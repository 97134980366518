.asistenca-items ul{
    list-style-position: inside;
}
.asistenca-items li{
    text-indent: -1.4em;
    margin-left: 2rem;
    padding-top: 0.6rem;
    padding-bottom: 0.3rem;
}
.asistenca-items p{
    margin-left: 0;
    padding-left: 0;
    padding-bottom: 1rem;
    padding-top: 1rem;
}