/**{
    width: 1vh;
}*/

.header{
    display: flex;
    justify-content: space-between;
    padding: 1rem 10vw; /*calc((100vw - 1400px)/2);*/
}

.header-image img{
    padding-left: 1rem;
    width: 8rem;
}

.header-text{
    padding-top: 2rem;
    padding-left: 1rem;
    font-size: 30px;
}

.header-text-marela{
    font-weight: 600;
}

@media(max-width: 1400px)
{
    .header{
        padding: 1rem 5vw; /*calc((100vw - 1000px)/2);*/
    }
    .header-text{
        font-size: 25px;
    }
}

@media(max-width: 800px)
{
    .header-text{
        display: none;
    }

    .header-image img{
        padding-left: 0rem;
    }

    .header-image{
        margin: 0 auto;
    }

    .header{
        display: none;
    }
}