.obvestila{
    display: flex;
    gap: 2rem;
    flex-direction: column;

}

.obvestila img{
    width: 100%;
    border-radius: 2rem;
}

.voscilo img{
    max-width: 70rem;
}

.voscilo{
    padding-left: 4rem;
    padding-right: 4rem;
    padding-bottom: 2rem;
    padding-top: 2rem;
    border: 4px solid black;
    border-radius: 2rem;
    max-width: 60rem;
    height: fit-content;
}

.glasilo{
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    padding-top: 2rem;
    border: 4px solid black;
    border-radius: 2rem;
    width: fit-content;
}

@media(max-width: 900px){
    .voscilo{
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-bottom: 0.5rem;
        padding-top: 1rem;
    }

    .obvestila{
        display: flex;
        gap: 2rem;
        flex-direction: column;
        padding: 0;
    }

}