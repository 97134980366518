@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

*{
    margin: 0;
    font-family: "Poppins";
}

@media(max-width: 700px)
{
    * p{
        font-size: smaller;
    }
}