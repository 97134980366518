.eu-img img{
    width: 100%;
}
.kurjenje{
    width: 100%;
}
.infografika{
    width: 100%;
}
.grafika1{
    width: 100%;
}
.grafika2{
    width: 100%;
}
.prim{
    width: 100%;
}
.pom{
    width: 100%;
}
.alk{
    width: 100%;
}

.povezava{
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
    border: 4px solid black;
    border-radius: 2rem;
    width: fit-content;
    margin-top: 1rem;
}

.sodelovanja img{
    padding-top: 1rem;
    width: 60%;
}

.sodelovanja p{
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.sodelovanja li{
    padding-top: 1rem;
    padding-bottom: 1rem;
    list-style-position: inside;
}
.sodelovanja ul{
    text-indent: -1.4em;
    margin-left: 2rem;
}

.sodelovanja h3{
    padding-top: 1em;
}

@media(max-width: 700px){
    .sodelovanja img{
        padding-top: 0.5rem;
        width: 100%;
    }
}