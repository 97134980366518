.pages-items{
    padding: 1rem 10vw; /*calc((100vw - 78.5%)/2);*/
    margin: 0 20px;
}
.pages-items p{
    font-size: 1.3rem;
    padding-left: 0px;
}
.pages-items div{
    margin-bottom: 2rem;
}

@media(max-width: 1400px)
{
    .pages-items{
        padding: 1rem 5vw;/*calc((100vw - 88.5%)/2);*/
    }
}
@media(max-width: 1100px)
{
    .pages-items{
        padding: 1rem 1vw; /*calc((100vw - 88.5%)/2);*/
    }
    .pages-items p{
        font-size: 0.9rem;
    }
    .pages-items h1{
        font-size: 1.6rem;
    }
    .pages-items h2{
        font-size: 1.3rem;
    }
    .pages-items h3{
        font-size: 1.1rem;
    }
}

@media(max-width: 700px){
    .pages-items p{
        font-size: 0.8rem;
    }
    .pages-items h1{
        font-size: 1.4rem;
    }
    .pages-items h2{
        font-size: 1rem;
    }
    .pages-items h3{
        font-size: 0.9rem;
    }
}