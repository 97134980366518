.expand_menu div{
    margin-bottom: 0;
}

.expand_menu{
    transition: all 0.1s;
    user-select: none;
    width: fit-content;
    background-color: white;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
}

.expand_menu_activated{
    transition: all 0.1s;
    border: 4px solid black;
    border-radius: 2rem;
    padding-bottom: 1rem;
    margin-top: 1rem;
}

.expand_menu_text{
    display: flex;
    gap: 1rem;
    transition: all 0.1s;
    user-select: none;
    width: fit-content;
    background-color: white;
    padding-top: 0rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 0rem;
    border: 4px solid black;
    border-radius: 2rem;
}

.expand_menu_button_activated{
    transition: all 0.1s;
    margin-top: 0.2rem;
}

.expand_menu_button:active{
    transition: all 0.1s;
    background-color: darkgrey;
}

.expand_menu h2{
    margin: auto;
}

.expand_menu ul{
    padding-left: 0rem;
}

.expand_menu p{
    padding-left: 2rem;
    padding-right: 1rem;
}

.vsebina_expand_menu{
    user-select:text;
}

@media(max-width: 900px){
    
    .expand_menu{
        transition: all 0.1s;
        user-select: none;
        width: fit-content;
        background-color: white;
        padding-top: 0.5rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-bottom: 0.3rem;
    }


    .expand_menu_text{
        display: flex;
        gap: 0.5rem;
        transition: all 0.1s;
        user-select: none;
        width: fit-content;
        background-color: white;
        padding-top: 0rem;
        padding-left: 0.7rem;
        padding-right: 0.7rem;
        padding-bottom: 0rem;
        border: 3px solid black;
        border-radius: 2rem;
    }

    .expand_menu_activated{
        transition: all 0.1s;
        border: 3px solid black;
        border-radius: 2rem;
        padding-bottom: 1rem;
        margin-top: 1rem;
    }
    
    .expand_menu p{
        padding-left: 0.5rem;
        padding-right: 0.3rem;
    }
}