.navbar{
    background-color: #0044aa;
    white-space: nowrap;
}

.navbar .menu-icon{
    display: none;
}

.list-of-items{
    display: flex;
    justify-content: space-between;
    padding: 1rem 9.2vw; /*calc((100vw - 80%)/2);*/
}

.list-of-items li{
    list-style: none;
    text-decoration: none;
    color: white;
    font-size: 30px;
    padding-left: 1vw;
    padding-right: 1vw;
    margin: 0 20px;

    /*border: 1px solid transparent;
    border-radius: 4px;*/
}
/*.list-of-items li:hover{
    border-color: #0066ff;
    background-color: #0066ff;
    transition: all ease 0.5s;
}*/


.list-of-items-icons{
    position: relative;
    top: 0.8vh;
}

.navbar-logo-text{
    display: none;
}

@media(max-width: 1500px)
{
    .list-of-items{
        padding: 1rem 4.2vw; /*calc((100vw - 90%)/2);*/
    }
}

@media(max-width: 1200px)
{
    .navbar{
        height: 100px;
        /*position:relative;*/
        /*width: 100%;*/
        display: flex;
    }
    .list-of-items.active{
        left: 0%;
        transition: left 0.4s ease;
    }
    .list-of-items{
        position: absolute;
        background-color: #0044aa;
        display: flex;
        flex-direction: column;
        row-gap: 30px;
        top: 15rem;
        padding: 25px 0px;
        width: 100%;
        height: fit-content;
        place-items: center;
        left: -100%;
        transition: left 0.4s ease;
    }
    .navbar .menu-icon{
        display:block;
        position: relative;
        top: 25px;
        right: 5%;
        color: white;
        cursor: pointer;
        margin-left: auto; 
        margin-right: 0;
    }
    /*.list-of-items li{
        display: none;
    }*/
}

@media(max-width: 800px)
{
    .list-of-items{
        top: 6rem;
    }
    .navbar-logo-text{
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 2rem;
        display: flex;
        gap: 1rem;
        position: relative;
        background-color: white;
        border: 0.5rem solid white;
        border-radius: 1rem;
    }
    .navbar-logo-text img{
        width: 2.5rem;
    }
    .navbar-logo-text p{
        margin: auto;
        padding-right: 1rem;
    }
}

@media(max-width: 399px)
{
    .navbar-logo-text p{
        display: none;
    }
}