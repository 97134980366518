
.domov{
    margin: 0;
    padding-top: 1rem;
}

.domov-items{
    padding-top: 2rem;
    display: flex;
    gap: 3rem;
    flex-wrap: wrap;
}
.domov-items strong{
    font-size: x-large;
}

.osebna-izkaznica{
    padding-top: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    border: 4px solid black;
    padding-bottom: 1rem;
    border-radius: 2rem;
    /*background-color: #7dfe50;*/
}
.kontakt{
    padding-top: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 1rem;
    border: 4px solid black;
    border-radius: 2rem;
}
@media(max-width: 700px)
{
    .domov-items strong{
        font-size: small;
    }
    .domov-items{
        gap: 0.5rem;
    }
}